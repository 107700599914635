import type { Ref } from "vue";

import {
  type BrandingCampaignsListPageReportsQueryVariables,
  buildQuery,
  buildQueryKey,
  graphql,
  runGraphqlQuery,
} from "@/graphql";

const query = graphql(/* GraphQL */ `
  query BrandingCampaignsListPageReports($campaignIds: [ID!]!, $startDate: String!, $endDate: String!, $today: String!) {
    campaignReports(campaignType: BRANDING, campaignIds: $campaignIds, startDate: $startDate, endDate: $endDate, reportType: PROBABILISTIC) {
      campaignId
      report {
        ...brandingReport
      }
    }
    todaySpentBudgetByCampaignIds: campaignReports(
      campaignType: BRANDING
      campaignIds: $campaignIds
      startDate: $today
      endDate: $today
      reportType: PROBABILISTIC
    ) {
      campaignId
      report {
        billableSpend
      }
    }
  }
`);

function getQueryOptions(variables: Ref<BrandingCampaignsListPageReportsQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, variables.value);
    },
  };
}

export function useBrandingCampaignsListPageReportsQuery(
  variables: Ref<BrandingCampaignsListPageReportsQueryVariables>,
  { enabled }: { enabled: Ref<boolean> },
) {
  return buildQuery({
    ...getQueryOptions(variables),
    enabled,
  });
}
