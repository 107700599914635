import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { ClientError, RequestOptions, Variables } from "graphql-request";
import { GraphQLClient } from "graphql-request";

import useAuthentication from "@/composables/use-authentication";
import { config } from "@/config";

export { graphql } from "./generated";

export * from "./generated/graphql";
export * from "./helpers/mutation";
export * from "./helpers/query";
export * from "./helpers/utils";

let authRetryCount = 0;

/**
 * Helper that runs a graphql query with the access token and error management.
 */
export async function runGraphqlQuery<Q, V extends Variables>(
  queryDocument: TypedDocumentNode<Q, V>,
  variables?: V,
): Promise<Q | null> | never {
  const { accessToken } = useAuthentication();
  const client = new GraphQLClient(config.graphqlEndpointUrl, {
    headers: {
      Authorization: `Bearer ${accessToken.value ?? ""}`,
    },
  });

  const options: RequestOptions = {
    document: queryDocument,
    variables,
  };

  try {
    const data = await client.request(options);
    authRetryCount = 0;
    return data as Q | null;
  }
  catch (e) {
    /**
     * Handle the unauthenticated and forbidden error
     */
    const error = e as ClientError;
    const graphqlErrors = error.response.errors ?? [];

    console.error("GraphQL error", error.response.errors ?? e, queryDocument);

    const hasAuthenticationError = error.response.status === 401;
    if (hasAuthenticationError) {
      if (authRetryCount > 5) {
        const { logout, login } = useAuthentication();
        await logout();
        await login(window.location.pathname);
        return null;
      }
      const { refreshAccessToken } = useAuthentication();
      const token = await refreshAccessToken();
      client.setHeader("Authorization", `Bearer ${token}`);
      authRetryCount += 1;
    }

    const isForbiddenError = !!graphqlErrors.find(
      err => err.extensions?.code === "FORBIDDEN",
    );
    if (isForbiddenError) {
      window.location.replace(`/forbidden?next=${window.location.pathname}`);
      return null;
    }

    throw e;
  }
}
