import type { Ref } from "vue";

import {
  buildQuery,
  buildQueryKey,
  type CampaignsListPageReportsQueryVariables,
  graphql,
  runGraphqlQuery,
} from "@/graphql";

const query = graphql(/* GraphQL */ `
  query CampaignsListPageReports($campaignIds: [ID!]!, $startDate: String!, $endDate: String!, $today: String!) {
    campaignReports(campaignType: PERFORMANCE, campaignIds: $campaignIds, startDate: $startDate, endDate: $endDate, reportType: PROBABILISTIC) {
      campaignId
      report {
        ...performanceReport
      }
    }
    todaySpentBudgetByCampaignIds: campaignReports(
      campaignType: PERFORMANCE
      campaignIds: $campaignIds
      startDate: $today
      endDate: $today
      reportType: PROBABILISTIC
    ) {
      campaignId
      report {
        billableSpend
      }
    }
  }
`);

function getQueryOptions(variables: Ref<CampaignsListPageReportsQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, variables.value);
    },
  };
}

export function useCampaignsListPageReportsQuery(
  variables: Ref<CampaignsListPageReportsQueryVariables>,
  { enabled }: { enabled: Ref<boolean> },
) {
  return buildQuery({
    ...getQueryOptions(variables),
    enabled,
  });
}
