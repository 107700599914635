import { computed, ref, type Ref } from "vue";

import type { ReportingDateRange } from "@/composables/use-reporting-daterange";
import { type BrandingCampaignsListPageQueryVariables, type BrandingCampaignsListPageReportsQueryVariables, type BrandingReportFragment, CampaignStatus } from "@/graphql";
import { blankBrandingReport } from "@/pages/campaigns/campaign-details/blank-report";
import { extractDateFromDatetime } from "@/utils/common";

import { useBrandingCampaignsListPageQuery } from "./queries/branding-campaigns.query.graphql";
import { useBrandingCampaignsListPageReportsQuery } from "./queries/reporting.query.graphql";
import type { BrandingCampaignsListRow } from "./types";

export function useData({ showArchived, reportingDateRange }: { showArchived: Ref<boolean>; reportingDateRange: ReportingDateRange }) {
  const brandingCampaignsQueryVariables = computed<BrandingCampaignsListPageQueryVariables>(() => ({
    showTestMode: true,
    sorts: "-creationDate",
    status: [
      CampaignStatus.Running,
      CampaignStatus.Pending,
      CampaignStatus.Stopped,
      CampaignStatus.Draft,
      ...(showArchived.value ? [CampaignStatus.Archived] : []),
    ],
  }));

  const {
    data: campaignsData,
    isPending: isCampaignsLoading,
    error: campaignsError,
  } = useBrandingCampaignsListPageQuery(brandingCampaignsQueryVariables);

  const campaigns = computed(() => campaignsData.value?.brandingCampaigns.edges.map(o => o.node) ?? []);

  const fetchedCampaignsIds = computed(() => campaigns.value.map(o => o.id));

  /**
   * Reports
   */

  const isReportingQueryEnabled = computed(
    () => fetchedCampaignsIds.value.length > 0,
  );

  const reports = ref<Record<string, BrandingReportFragment>>({});
  const todaySpentBudgetByCampaignId = ref<Record<string, number>>({});

  const reportQueryVariables = computed<BrandingCampaignsListPageReportsQueryVariables>(() => ({
    campaignIds: fetchedCampaignsIds.value,
    startDate: extractDateFromDatetime(reportingDateRange.value.start),
    endDate: extractDateFromDatetime(reportingDateRange.value.end),
    today: extractDateFromDatetime(new Date()),
  }));

  const {
    isPending: isReportsLoading,
    onResult: onResultReports,
    isError: isReportsError,
  } = useBrandingCampaignsListPageReportsQuery(reportQueryVariables, {
    enabled: isReportingQueryEnabled,
  });

  onResultReports((data) => {
    reports.value = Object.fromEntries(data.campaignReports.map(({ campaignId, report }) => [campaignId, report]));

    todaySpentBudgetByCampaignId.value = Object.fromEntries(data.todaySpentBudgetByCampaignIds.map(
      ({ campaignId, report }) => [campaignId, report.billableSpend],
    ));
  });

  const rows = computed<BrandingCampaignsListRow[]>(() =>
    campaigns.value.map((campaign) => {
      const report = reports.value[campaign.id] ?? blankBrandingReport;
      const todaySpend = todaySpentBudgetByCampaignId.value[campaign.id] ?? 0;

      let todaySpendProgression = 0;
      if (campaign.dailyBudget && todaySpend) {
        todaySpendProgression = (todaySpend / campaign.dailyBudget) * 100;
      }

      return {
        ...campaign,
        ...report,
        todaySpend,
        todaySpendProgression,
      } satisfies BrandingCampaignsListRow;
    }),
  );

  return {
    rows,
    isCampaignsLoading,
    campaignsError,
    isReportsLoading,
    isReportsError,
  };
}
