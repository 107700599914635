import type { BrandingReportFragment, PerformanceReportFragment } from "@/graphql";

export type NullablePerformanceReport = Record<keyof Omit<PerformanceReportFragment, "__typename">, number | null>;

const blankPerformanceReport: NullablePerformanceReport = {
  nbOfImpressions: null,
  nbOfClicks: null,
  nbOfInstalls: null,
  clickthroughRate: null,
  conversionRate: null,
  installsPerMille: null,
  estimatedSpend: null,
  billableSpend: null,
  effectiveCostPerInstall: null,
  effectiveCostPerMille: null,
  d0Roas: null,
  lifeTimeValueAdvertisingD0: null,
  lifeTimeValueInAppPurchaseD0: null,
  lifeTimeValueD0: null,
  d1Roas: null,
  lifeTimeValueAdvertisingD1: null,
  lifeTimeValueInAppPurchaseD1: null,
  lifeTimeValueD1: null,
  d3Roas: null,
  lifeTimeValueAdvertisingD3: null,
  lifeTimeValueInAppPurchaseD3: null,
  lifeTimeValueD3: null,
  d7Roas: null,
  lifeTimeValueAdvertisingD7: null,
  lifeTimeValueInAppPurchaseD7: null,
  lifeTimeValueD7: null,
  d14Roas: null,
  lifeTimeValueAdvertisingD14: null,
  lifeTimeValueInAppPurchaseD14: null,
  lifeTimeValueD14: null,
  inAppPurchaseRevenue: null,
  advertisingRevenue: null,
  totalRevenue: null,
};

export { blankPerformanceReport };

export type NullableBrandingReport = Record<keyof Omit<BrandingReportFragment, "__typename">, number | null>;

const blankBrandingReport: NullableBrandingReport = {
  nbOfImpressions: null,
  nbOfClicks: null,
  nbOfInstalls: null,
  clickthroughRate: null,
  conversionRate: null,
  installsPerMille: null,
  estimatedSpend: null,
  billableSpend: null,
  effectiveCostPerInstall: null,
  effectiveCostPerMille: null,
  advertisingRevenue: null,
  totalRevenue: null,
};

export { blankBrandingReport };
