<script setup lang="ts">
import type { MenuItem } from "primevue/menuitem";
import { reactive } from "vue";
import { RouterView } from "vue-router";

import AppMenu from "@/components/business/AppMenu/AppMenu.vue";
import CreateAssetsWatcher from "@/components/business/CreateAssetsWatcher/CreateAssetsWatcher.vue";
import UserMenu from "@/components/modules/UserMenu/UserMenu.vue";
import links from "@/router/links";

const items = reactive<MenuItem[]>([
  {
    label: "Performance campaigns",
    icon: "fa fa-megaphone",
    to: links.campaigns.list,
  },
  {
    label: "Branding campaigns",
    icon: "fa-solid fa-tv-retro",
    to: links.brandingCampaigns.list,
  },
  {
    label: "Assets",
    icon: "fa fa-folder-image",
    to: links.assets.list,
  },
  {
    label: "Apps",
    icon: "fa-thin fa-rectangle-history",
    to: links.apps.list,
  },
]);
</script>

<template>
  <div class="root-page z-0 flex h-screen w-full flex-col overflow-hidden sm:flex-row">
    <AppMenu :items="items">
      <template #end>
        <UserMenu />
      </template>
    </AppMenu>
    <div
      class="router-container relative flex flex-1 flex-col overflow-y-auto p-2 pt-8 sm:p-4 sm:pb-2 sm:pt-8 md:p-8 md:pb-2"
    >
      <div class="flex flex-1 flex-col">
        <div class="flex flex-1 flex-col">
          <div>
            <RouterView />
          </div>
        </div>
        <CreateAssetsWatcher />
        <footer class="root-page-footer w-full px-6 pt-12 sm:px-0">
          <div class="text-color-secondary">
            Copyright @Voodoo
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
