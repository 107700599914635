import type { MaybeRef } from "@vueuse/core";
import { type ComputedRef, unref } from "vue";

import { buildQuery, buildQueryKey, type CampaignsListPageQueryVariables, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query CampaignsListPage($status: [CampaignStatus!]!, $showTestMode: Boolean, $sorts: String) {
    performanceCampaigns(first: 10000, status: $status, showArchived: true, showTestMode: $showTestMode, sorts: $sorts) {
      edges {
        node {
          ...CampaignsListPageCampaign
        }
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment CampaignsListPageCampaign on PerformanceCampaign {
    id
    name
    store
    type
    roasType
    app {
      ...app
    }
    adsManagement {
      hasAutomaticAdsManagement
      adsPlacement
    }
    totalDailyBudget
    countries
    deviceType
    status
    isTestMode
  }
`);

export function getQueryOptions(variables: MaybeRef<CampaignsListPageQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, unref(variables));
    },
  };
}

export function useCampaignsListPageQuery(variables: ComputedRef<CampaignsListPageQueryVariables>) {
  return buildQuery(getQueryOptions(variables));
}
